import React, { Component } from 'react';
import { BrowserRouter as Router, Route } from "react-router-dom";
import FilterHomeScreen from './containers/FilterHomeScreen';
import { connect } from 'react-redux';
import { setMediaFilter } from './actions';
import ConsentScreen from './containers/ConsentScreen';
import IssueScreen from './containers/IssueScreen';
import SelectScreen from './containers/SelectScreen';
import CompleteScreen from './containers/CompleteScreen';
import ResultScreen from './containers/ResultScreen';
import OtherIssueScreen from './containers/OtherIssueScreen';
import AboutScreen from './containers/AboutScreen';
import AnswerIssueScreen from './containers/AnswerIssueScreen'
import NewCompleteScreen from './containers/NewCompleteScreen'
import UpdateOpinionScreen from './containers/UpdateOpinionScreen'
import ExpectScreen from './containers/ExpectScreen';
import ReactGA from 'react-ga'
import GA from './components/GA'
const mapStateToProps = (state) => {
  return {
      isMobile: "MOBILE" === state.setMediaFilter
  }
}

class App extends Component {
  constructor() {
    super();
    this.state = {
      width: window.innerWidth,
    };
    this.dispatchMediaFilter = this.dispatchMediaFilter.bind(this);
    this.onRouteChange = this.onRouteChange.bind(this)
  }

  componentDidMount() {
    this.dispatchMediaFilter();
    window.addEventListener('resize', this.handleWindowSizeChange);
    window.Kakao.init("2837d2d95a170f0d67c5f1e7c5384894");
    ReactGA.initialize('UA-149436008-1', {
      debug: true,
      titleCase: false,
      gaOptions: {
        siteSpeedSampleRate: 100,
      }
    });
  }
  
  // make sure to remove the listener
  // when the component is not mounted anymore
  componentWillUnmount() {
    window.removeEventListener('resize', this.handleWindowSizeChange);
  }
  
  handleWindowSizeChange = () => {
    this.setState({ width: window.innerWidth });
    this.dispatchMediaFilter();
  };

  dispatchMediaFilter() {
    const { dispatch } = this.props;
    const { width } = this.state;

    const isMobile = width <= 640 ? "MOBILE" : "WEB";
    dispatch(setMediaFilter(isMobile));
  }

  onRouteChange() {
    console.log('aaa')
    ReactGA.set({ page: window.location.pathname });
    ReactGA.pageview(window.location.pathname);
  }
  
  render() {
    return (
      <Router>
        { GA.init({titleCase: false,
          gaOptions: {
            siteSpeedSampleRate: 100,
          }}) && <GA.RouteTracker /> }
        <Route exact path="/" component={FilterHomeScreen}/>
        <Route exact path="/AnswerIssue" component={AnswerIssueScreen}/>
        <Route exact path="/NewComplete" component={NewCompleteScreen}/>
        <Route excat path="/about" component={AboutScreen} />
        <Route exact path="/consent" component={ConsentScreen} />
        <Route exact path="/issue" component={IssueScreen} />
        <Route exact path="/select" component={SelectScreen} />
        <Route exact path="/complete" component={CompleteScreen} />
        <Route path="/result/:key" component={ResultScreen} />
        <Route exact path="/other" component={OtherIssueScreen} />
        <Route exact path="/updateOpinion" component={UpdateOpinionScreen} />
        <Route exact path="/expect/:key" component={ExpectScreen} />
      </Router>
    )
  }

}

export default connect(mapStateToProps)(App);

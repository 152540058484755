
const saveStereotypes = (state = {}, action) => {
  switch (action.type) {
      case 'SAVE_STEREOTYPE':
          return action.stereotypes
      default:
          return state
  }
}

export default saveStereotypes
import React from 'react';
import { connect } from 'react-redux';

const mapStateToProps = (state) => {
    const { setMediaFilter, selectIssue, selectOption } = state;
    return {
        isMobile: "MOBILE" === setMediaFilter,
        issue: selectIssue,
        option: selectOption,
    }
  }

class Button extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            buttonIsHovered: false,
            buttonIsClicked: false,
        }
        this.setButtonHovered = this.setButtonHovered.bind(this);
        this.setButtonClicked = this.setButtonClicked.bind(this);
    }

    setButtonHovered(bl) {
        const { isMobile } = this.props

        if (isMobile) {
            return false
        }
        this.setState({
            buttonIsHovered: bl
        })
    }

    setButtonClicked() {
        // const { buttonIsClicked } = this.state;
        const { content, option, issue, id } = this.props
        this.props.onClick(!(content===option || id===issue));
        this.setState({
            buttonIsClicked: (content===option || id===issue)
        })
    }
    
    render() {
        const { buttonIsHovered } = this.state;
        const { url, content, issue, option, onClick, id, disabled, src } = this.props;
        return(
            <div className={`button ${this.props.type} ${!disabled && buttonIsHovered ? 'hover' : ''} ${!disabled && (content===option || id===issue)  ? 'pressed' : ''}`} 
                onMouseEnter={() => this.setButtonHovered(true)}
                onMouseLeave={() => this.setButtonHovered(false)}
                onClick={onClick ? this.setButtonClicked : null}>
                {
                url ?
                    <a href={url}>
                        <div className={`button-text`}>
                            {src ? '' : this.props.content}
                        </div>
                    </a>
                    :
                    <div className={`button-text`}>
                        {src ? '' : this.props.content}
                    </div>
                }
            </div>
        )
    }
}

export default connect(mapStateToProps)(Button);
import React from 'react';
import PropTypes from 'prop-types';
import Header from '../../components/Header';
import Button from '../../components/Button';
import Response from '../../components/Response';
import { saveOpinion, selectOption } from '../../actions';
import CircularProgress from '@material-ui/core/CircularProgress';

import { connect } from 'react-redux';

const mapStateToProps = (state) => {
    const { setMediaFilter, selectIssue, selectOption, saveAPIToken, saveIssueTitle} = state;
    return {
        isMobile: "MOBILE" === setMediaFilter,
        issue: selectIssue,
        option: selectOption,
        token: saveAPIToken,
        issueTitle: saveIssueTitle
    }
  }

const mapDispatchToProps = dispatch => {
    return {
        opinion: (opinion) => dispatch(saveOpinion(opinion)),
        select: (option) => dispatch(selectOption(option))
    }
}

class SelectScreen extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            issues: [],
            opinions: [],
            issueIndex: 0,
            title: '',
            description: '',
            identityQuestions: [],
            responseType: 'likertscale',
            identityOptions: [],
            info: {marks: ['매우 반대', '반대', '중립', '찬성', '매우 찬성']},
            question: 0,
            stance: -1,
            currentQuestionType: 'stance',
            questionOrder: ['stance', 'freeform', 'identityOptions', 'identityQuestions'],
            identityOptionIndex: 0,
            identityQuestionIndex: 0,
            argument: '',
            identities: [],
            isLoading: false,
            author: {age: '', gender: '', occupation: '', politicalStance: '',},
        }
    }

    componentDidMount() {
        const { token } = this.props;
        // this.setState({
        //     title: issueTitle
        // })
        fetch(`${process.env.REACT_APP_URL}/dev/issuesNew`, {
            credentials: 'include',
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
            // description, identityQuestions, title, _id
            .then((res) => res.json())
            .then((response) => {
                this.setState({
                    issues: response.issues,
                    identityQuestions: [],
                    identityOptions: response.identityOptions,
                    title: response.issues[0].title
                })
            })
            .catch((err) => console.log(err));
        this.onClickHandler = this.onClickHandler.bind(this);
        this.toComplete = this.toComplete.bind(this);
    }

    toComplete() {
        const { token} = this.props;
        const { author, opinions } = this.state;
        // const users = {
        //     age: author.age,
        //     gender: author.gender,
        //     occupation: author.occupation,
        //     politicalStance: author.politicalStance
        // }
        // const opinions = {
        //     issue: issue, // 서버에서 돌려준 issue의 object ID,
        //     stance: stance, // 정책에 대한 5-point Likert scale (1: 매우 반대, 5: 매우 찬성)
        //     argument: argument, // "왜 그렇게 생각하셨나요?"에 대한 대답.
        //     identities: identities
        // };
        // localStorage.setItem('users', JSON.stringify(users));
        // localStorage.setItem('opinions', JSON.stringify(opinions));
        if (token) {
            if(author.age || author.gender || author.occupation || author.politicalStance) {
                fetch(`${process.env.REACT_APP_URL}/dev/users`, {
                    method: 'put',
                    credentials: 'include',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`
                    },
                    body: JSON.stringify({
                        age: author.age,
                        gender: author.gender,
                        occupation: author.occupation,
                        politicalStance: author.politicalStance
                    })
                })
            }
            localStorage.setItem('myOpinions', JSON.stringify(opinions))
            const promises = opinions.map((opinion) => {
                return fetch(`${process.env.REACT_APP_URL}/dev/opinions`, {
                    method: 'post',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`
                    },
                    body: JSON.stringify(opinion)
                }) 
            })
            this.setState({
                isLoading: true
            })
            Promise.all(promises).then(() => {
                this.setState({
                    isLoading: false
                })
                // console.log('Posting done! If the page changes before you see me, it is f***ed')
                this.props.history.push('/NewComplete')
            })
        }
        
    }
    
    onClickHandler() {
        const { option, select } = this.props;
        const { currentQuestionType, identityOptions, identityOptionIndex, identityQuestions, author, issueIndex, issues, opinions } = this.state;
        const _options = ['age', 'gender', 'occupation', 'politicalStance']
        if ( currentQuestionType === 'stance' && issues ) {
            const stance = parseInt(option);
            const newOpinion = {
                issue: issues[issueIndex]._id,
                issueTitle: issues[issueIndex].title,
                issueKey: issues[issueIndex].key,
                stance: stance,
                argument: '',
                identities: []
            }
            // console.log(newOpinion)
            if (issueIndex + 1 < issues.length) {
                select('')
                this.setState({
                    opinions: opinions.concat(newOpinion),
                    title: issues[issueIndex+1].title,
                    issueIndex: issueIndex + 1,
                    currentQuestionType: 'stance'
                }, () => select('3'))
            } else {
                select('')

                this.setState({
                    opinions: opinions.concat(newOpinion),
                    currentQuestionType: 'identityOptions' //TODO: incorporate IQs
                }, () => select(''))
            }
        }
        // else if ( currentQuestionType === 'freeform' ) {
        //     const argument = option;
        //     console.log(argument);
        //     this.setState({
        //         argument: argument
        //     }, () => {
        //         select('');
        //         if ( identityOptions.length > 0 ) { this.setState({currentQuestionType: 'identityOptions'})}
        //         else if ( identityQuestions.length > 0) { this.setState({currentQuestionType: 'identityQuestions'})}
        //         else { this.toComplete() }
        //     })
        // }
        else if ( currentQuestionType === 'identityOptions' ) {
            author[_options[identityOptionIndex]] = option;
            this.setState({
                author: author
            }, () => select(''))
            if ( identityOptionIndex + 1 < identityOptions.length ) {
                this.setState({
                    identityOptionIndex:  identityOptionIndex+1
                })
            }
            else if ( identityQuestions.length > 0) { this.setState({currentQuestionType: 'identityQuestions'})}
            else { this.toComplete() }
        }
        // else if ( currentQuestionType === 'identityQuestions' ) {
        //     const _id = identityQuestions[identityQuestionIndex]._id;
        //     identities.push({identityQuestion: _id, value: option});
        //     this.setState({
        //         identities: identities
        //     }, () => select(''))
        //     if ( identityQuestionIndex + 1 < identityQuestions.length ) {
        //         this.setState({
        //             identityQuestionIndex:  identityQuestionIndex+1
        //         })
        //     }
        //     else { this.toComplete() }
        // }
        // else if ( currentQuestionType === 'identityQuestions' && identityQuestionIndex === identityQuestions.length ) {
        //     // send to the server
        //     this.toComplete()
        // }
    }

    render() {
        // const options = ['20대', '30대', '40대', '50대', '60대']
        const { title, identityQuestions, identityQuestionIndex,
            info, currentQuestionType, identityOptions, identityOptionIndex, issueIndex, isLoading } = this.state;
        const { option } = this.props;
        const numbers = ['첫', '두','세','네','다섯','마지막으로, 여섯']
        return (
            <div className="wrapper">
                <Header />
                {
                    isLoading && 
                    <div className="answer-issue-loader">
                        <div className="circular-progress-wrapper">
                            <CircularProgress color="secondary"/>
                        </div>
                    </div>
                }
                {
                    currentQuestionType === 'stance' ?
                    <div className="container">
                        {
                            issueIndex === 0 ? 
                            (
                                <div className="text-light text-center">
                                    먼저, 사회 이슈에 대해 어떻게 생각하시는지 알려주세요.
                                </div>
                            ) :
                            null
                        }
                        <div className="text-light text-center">
                           {numbers[issueIndex]} 번째 이슈입니다.
                        </div>
                        <div className="title text-light text-center title-issue">
                            "{title}"에 대해 어떻게 생각하시나요?
                        </div>
                        <Response key={`issue${issueIndex}`} responseType={"likertscale"} options={info} />
                    </div>
                    :
                    currentQuestionType === 'freeform' ?
                    <div className="container">
                        {
                            issueIndex === 0 ? 
                            <div className="text-light text-center">
                                이제 본인에 대한 몇 가지 정보를 알려주세요.
                            </div>
                            : null
                        }
                        <div className="title text-light text-center title-issue">
                            그렇게 생각하신 이유는 무엇인가요?
                        </div>
                        <Response responseType={"freeform"} />
                    </div>
                    :
                    currentQuestionType === 'identityOptions' ?
                    <div className="container">
                        {
                            identityOptionIndex === 0 ?
                            (<div className="text-light text-center">
                                이제, 몇 가지 인적 사항을 알려주세요.
                            </div>)
                            : null
                        }
                        <div className="title text-light text-center title-issue">
                            {identityOptions[identityOptionIndex].question}
                        </div>
                    <Response responseType={identityOptions[identityOptionIndex].type} options={identityOptions[identityOptionIndex].options} />
                    </div>
                    :
                    currentQuestionType === 'identityQuestions' ?
                    <div className="container">
                        <div className="title text-light text-center title-issue">
                        {identityQuestions[identityQuestionIndex].question}
                        </div>
                    <Response responseType={identityQuestions[identityQuestionIndex].type} options={identityQuestions[identityQuestionIndex].options} />
                    </div>
                    :
                    null
                }
                {/* <Response responseType={responseType} options={info}/> */}
                <div className="next">
                    <Button type={`select text-bold ${((currentQuestionType === 'freeform' && option.length < 10) || (currentQuestionType !== 'freeform' && option.length === 0)) ? 'disabled' : 'button-gradient'}`} content="다음" onClick={(currentQuestionType === 'freeform' && option.length < 10) || (currentQuestionType !== 'freeform' && option.length === 0) ? null : this.onClickHandler}/>
                </div>
            </div>
        )
    }
}

SelectScreen.propTypes = {
    isMobile: PropTypes.bool.isRequired,
    issue: PropTypes.string.isRequired,
    option: PropTypes.string
}

export default connect(mapStateToProps, mapDispatchToProps)(SelectScreen);
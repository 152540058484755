import { combineReducers } from 'redux';
import setMediaFilter from './setMediaFilter';
import selectIssue from './selectIssue';
import selectOption from './selectOption';
import saveProfile from './saveProfile';
import saveAPIToken from './saveAPIToken';
import saveOpinion from './saveOpinion';
import saveIssueTitle from './saveIssueTitle';
import saveIssueKey from './saveIssueKey';
import saveStereotypes from './saveStereotypes';

const rootReducer = combineReducers({
    setMediaFilter,
    selectIssue,
    selectOption,
    saveProfile,
    saveAPIToken,
    saveOpinion,
    saveIssueTitle,
    saveIssueKey,
    saveStereotypes,
});

export default rootReducer;